import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import PropTypes from 'prop-types';

/**
 * @class
 * @param {string} text Data for rich text elements
 * @param {string} urlTarget for url element
 * @param {string} paragraphCustomClasses classes for Paragraph Item
 * @param {string} className classes for Rich text container
 *
 * @returns Richtext Component as per the data provided
 */

const HyperlinkComponent = ({ node, target, classNames, children }) => (
  <a
    target={target}
    rel='noopener noreferrer'
    href={node.data.uri}
    className={cx(
      'underline underline-offset-[3px] decoration-1 hover:text-primary ',
      classNames?.anchorClasses
    )}
  >
    {children}
  </a>
);

const ParagraphComponent = ({ node, children, classNames }) => (
  <p className={classNames?.paragraphCustomClasses}>{children}</p>
);

const RichText = ({
  text,
  classNames = { anchorClasses: '' },
  urlTarget = ''
}) => {
  const target = urlTarget === 'New Tab' ? '_blank' : '';

  const renderEmbeddedEntries = useCallback((contentType, data) => {
    {
      switch (contentType) {
        case 'cta':
          return (
            <a href={data?.url} className='mt-4 underline' target={target}>
              {data?.label}
            </a>
          );
        case 'informationModuleSection': {
          const { informationModuleRows } = data;
          return (
            <div>
              {informationModuleRows[0]?.fields?.informationModules.map(
                ({ fields }, index) => (
                  <div key={fields?.headingText} className='py-3'>
                    <h1>
                      {index + 1} . {fields?.headingText}
                    </h1>
                  </div>
                )
              )}
            </div>
          );
        }
        default:
          return <h1>{data?.fields?.label}</h1>;
      }
    }
  }, []);
  const options = useMemo(
    () => ({
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => (
          <HyperlinkComponent
            node={node}
            target={target}
            classNames={classNames}
          >
            {children}
          </HyperlinkComponent>
        ),
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
          const { target } = node?.data || {};
          const { id } = target.sys.contentType?.sys || {};
          return (
            <div className='mt-2'>
              {renderEmbeddedEntries(id, target?.fields)}
            </div>
          );
        },
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <ParagraphComponent node={node} classNames={classNames}>
            {children}
          </ParagraphComponent>
        )
      },
      renderText: text => {
        return text
          .split('\n')
          .map((text, i) => [i > 0 && <br key={i} />, text]);
      }
    }),
    [classNames, renderEmbeddedEntries, target]
  );
  const renderedText = documentToReactComponents(text, options);

  return (
    <div
      data-testid='rich-text '
      className={cx(
        'align-middle custom-hws-default-style',
        classNames?.container
      )}
    >
      {renderedText}
    </div>
  );
};

HyperlinkComponent.propTypes = {
  node: PropTypes.object.isRequired,
  target: PropTypes.string.isRequired,
  classNames: PropTypes.shape({
    anchorClasses: PropTypes.string
  }),
  children: PropTypes.node.isRequired
};

ParagraphComponent.propTypes = {
  node: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  classNames: PropTypes.shape({
    paragraphCustomClasses: PropTypes.string
  })
};

RichText.propTypes = {
  text: PropTypes.string.isRequired,
  classNames: PropTypes.shape({
    anchorClasses: PropTypes.string
  }),
  urlTarget: PropTypes.string
};

export default RichText;
